import { SET_ROUTER_MATCH } from '../../common/router';
import * as Routes from '../constants/routes';
import { saveForumDisplayState } from '@wix/communities-forum-client-commons/dist/src/services/session-storage-utils';
import {
  POST_LIST,
  CATEGORY_LIST,
  OTHER
} from '@wix/communities-forum-client-commons/dist/src/constants/display-states';

export default function forumDisplayStateMiddleware() {
  return () => next => action => {
    if (action.type === SET_ROUTER_MATCH) {
      switch (action.payload.route) {
        case Routes.ROUTE_CATEGORY:
          saveForumDisplayState(POST_LIST);
          break;
        case Routes.ROUTE_HOME:
          saveForumDisplayState(CATEGORY_LIST);
          break;
        default:
          saveForumDisplayState(OTHER);
      }
    }
    next(action);
  };
}
